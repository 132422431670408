
$color-body-background: #FAFAFA;
$color-overlay-color: rgba(0, 0, 0, 0.4);
$color-dark-color: #333;
$color-orange-color: #F04800;
$color-hover-color: #234658;

$media-max-xs: 479px;
$media-max-sm: 767px;
$media-max-md: 991px;
$media-max-lg: 1199px;

$media-min-xs: 480px;
$media-min-sm: 768px;
$media-min-md: 992px;
$media-min-lg: 1200px;