.member-contact i {
  &.fab, &.fas, &.far {
    margin-right: 10px;
  }
}


.team {
  margin-bottom: 60px;
  text-align: center;

  @media screen and (max-width: 767px) {
    text-align: center;
  }

  .member {
    background-size: contain;
    padding: 15px 15px;
    background: #FBFBFB no-repeat top right;
    font-size: 20px;

    .member-photo img {
      border-radius: 0;
      margin: 0 auto;
      width: 75%;
      max-width: 35rem;
    }

    .member-name {
      font-size: 2.6rem;
      color: $color-orange-color;
      margin-top: 30px;
    }

    .member-position {
      font-size: 1.6rem;
      margin: 5px 0 10px;
    }

    .member-contact {
      margin: 0;
      padding: 0;
      font-size: 1.6rem;

      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
    }

    .member-description {
      font-size: 1.6rem;
      padding: 10px 15px;
      margin: 30px -15px 30px;
      background: transparent;
      text-align: left;
      float: left;
      width: 50%;
    }
  }
}
/*

@media screen and (max-width: 992px) {
  .team .member .member-description {
    position: absolute;
    bottom: -80px;
    padding: 10px 15px;
    margin: 0 -15px;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .team .member .member-description {
    bottom: -90px;
  }
}
*/