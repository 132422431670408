html {
  //font-size: 20px;
  font-size: 62.5%;
}

.overlay {
  position: relative;

  &:before {
    position:absolute;
    display: block;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $color-overlay-color;
  }

  * {
    position: relative;
  }
}

.row-eq-height {
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    a.button.pull-down {
      position: absolute;
      bottom: -4rem;
      width: calc(100% - 6rem);
    }
  }

  @media screen and (max-width: 478px) {
    a.button.pull-down {
      width: calc(100% - 6rem);
    }
  }
}

.cards {
  margin-bottom: 11rem;

  @media screen and (max-width: 767px){
    text-align: center;
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    & > div[class*="col-"] {
      padding: 0 2.5rem 0 2.5rem;
    }
    /*
    & > div[class*="col-"]:first-child {
      padding: 0 3rem 0 0;
    }
    & > div[class*="col-"]:last-child {
      padding: 0 15px 0 3rem;
    }
    & > div[class*="col-"]:not(:first-child):not(:last-child) {
      padding: 0 3rem 0 3rem;
    }
    */
  }
}
.cards-with-background {
  color: white;
  background: linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
  ), no-repeat;
  background-size: cover;

  h3, p {
    padding: 1.5rem 3rem;

    @media screen and (max-width: 767px) {
      padding: 1.5rem 4.5rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .card h3.section-title:after {
    bottom: initial;
    left: 3rem;
    @media screen and (max-width: 767px) {
      left: 4.5rem;
    }
  }
}

.card {
  padding-left: 0;
  padding-right: 0;

  div {
    padding: 1.5rem 3rem;
    min-height: 350px;
    color: white;
    background: linear-gradient(
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
    ), no-repeat;
    background-size: cover;

    @media screen and (max-width: 992px) {
      min-height: 0;
    }

    @media screen and (max-width: 767px) {
      padding: 1.5rem 4.5rem;
    }
  }

  h3.section-title  {
    position: relative;
    &:after{
      background: #F04800;
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 3px;
    }
  }

  p {
    line-height: 2.2rem;
    font-size: 1.6rem;
  }
}


.icons-offers {
  margin-right: 2rem;
  width: 3rem;
  height: 3rem;

  @media screen and (max-width: 767px) {
    display: block;
    margin: 1rem auto;
  }
}

.section-intro {
  margin-top: 8rem;
  @media screen and (max-width: 768px) {
    margin-top: 4rem;
  }
  p {
    font-size: 1.8rem;
    margin-bottom: 2rem;

    .underline {
      position: relative;
      &:after{
        background: #F04800;
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
      }
    }
  }
}

.card-list {
  font-size: 1rem;
  line-height: 1.6rem;
  min-height: 160px;
}

i.fab, i.fa, i.far {
  vertical-align: middle;
}

.contact-us {
  background: url(../img/photos/call.jpg) no-repeat center center;
  background-size: cover;

  margin: 6rem -15px;
  @media screen and (min-width: 768px) {
    margin: 8rem -15px 8rem;
  }

  .overlay {
    //background: rgba(39,62,84,0.82);
    height: 100%;
    text-align: center;
    @media screen and (min-width: 768px) {
      padding: 3rem 0;
    }

    .call-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 2rem;
      color: #fff;
      line-height: 2.7rem;
      margin: 3rem 0;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 3.6rem;
        margin-bottom: 4rem;
      }
    }

    .call-link {
      margin-bottom: 4rem;
    }
  }
}