body {
  background: $color-body-background;
  color: $color-dark-color;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.6rem;
  padding-bottom: 70px;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-body-background;
  z-index: 1002;

  .loader {
    width: 16vw;
    height: 16vw;
    margin-left: -8vw;
    margin-top: -8vw;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;

    &:before {
      content: "";
      display: block;
      width: 8vw;
      height: 4vw;
      background: url('../img/head.svg') no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -2.8vw;
      margin-left: -4.8vw;
      animation-name: iconAni;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      -webkit-animation-name: iconAni;
      -webkit-animation-duration: 1.2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -1vw;
      top: -1vw;
      width: 100%;
      height: 100%;
      border: 1vw solid $color-orange-color;
      border-left-color: transparent;
      border-right-color: transparent;
      border-radius: 50%;
      animation-name: borderAni;
      animation-timing-function: linear;
      animation-duration: 2.4s;
      animation-iteration-count: infinite;
      -webkit-animation-name: borderAni;
      -webkit-animation-timing-function: linear;
      -webkit-animation-duration: 2.4s;
      -webkit-animation-iteration-count: infinite;
    }

    @keyframes iconAni {
      0% {transform: scale(1);}
      50% {transform: scale(1.3);}
      100% {transform: scale(1);}
    }

    @keyframes borderAni {
      from {transform: rotate(0deg);}
      to {transform: rotate(360deg);}
    }
  }

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.6px;
}

h3 {
  font-size: 30px;
}

h2 {
  &.section-title {
    font-size: 3.2rem;
    color: $color-dark-color;
    margin: 4rem 0 2rem;
    position: relative;
    &:after{
      background: #F04800;
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left:0;
      width: 4rem;
      height: 0.2rem;
    }
  }
}

.full-image {
  padding: 0;

  img {
    width: 100%;
  }
}

a,
a:hover,
a:visited {
  text-decoration: none;
  color: $color-orange-color;
  transition: all 0.3s ease;

  &:hover {
    color: $color-hover-color;
  }
}

.button {
  display: inline-block;
  border: 1px solid #F04800;
  border-radius: 2px;
  font-size: 1.3rem;
  color: #F04800;
  line-height: 3.8rem;
  height: 3.8rem;
  width: 238px;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    color: $color-hover-color;
    border-color: $color-hover-color;
  }

  &.button-full {
    background: $color-orange-color;
    color: #fff;

    &:hover {
      background: $color-hover-color;
      color: #fff;
    }
  }
}

button.button {
  line-height: normal;
}

#header {
  height: 100vh;
  &.half-height {
    height: 50vh;
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;

  .logo {
    margin-top: 7rem;
    display: block;
    text-align: center;

    img {
      width: 40%;
      max-width: 240px;
    }

    &.logo-home {
      padding-top: 15vh;
      text-align: center;


      img {
        width: 60%;
        max-width: 600px;
      }
    }
  }

  .motto {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 7rem;
    @media screen and (max-width: 767px){
      bottom: 12rem;
    }

    h1 {
      margin: 0;
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 1.3rem;
      margin-top: 0;
    }
  }

  .scroll-down {
    width: 3.6rem;
    height: 3.6rem;
    position: absolute;
    left: 50%;
    margin-left: -19px;
    cursor: pointer;
    bottom: 5rem;
    @media screen and (max-width: 767px){
      bottom: 6rem;
    }

    .scroll-indicator {
      position: absolute;
      left: 50%;
      font-size: 35px;
      animation: bounce 1s infinite;
      width: 3.6rem;
      height: 3.6rem;
      margin-left: -19px;
      cursor: pointer;
      color: white;

      @keyframes bounce {
        0% { top: 15%; }
        50%   { top: 35%; }
        100% { top: 15%; }
      }
    }
  }
}

#partners {
  background: #fff;
  padding: 1.5rem;

  div {
    text-align: center;
    width: 33%;
    float: left;

    img {
      display: inline-block;
      filter: grayscale(1);
      opacity: 0.6;
      transition: all 0.3s ease;
    }

    &:hover {
      img {
        filter: none;
        opacity: 1;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    font-size: 1.6rem;
    line-height: 2rem;
    height: 2rem;
    margin-top: -10px;
    width: 2rem;
    color: $color-dark-color;
    z-index: 100;

  }

  .slick-prev {
    left: 5px;
    text-align: left;
  }

  .slick-next {
    right: 5px;
    text-align: right;
  }
}

#benefits {
  margin: 3rem 0 0;
  padding: 0;

  li {
    padding: 0;
    margin: 1rem 0;
    list-style-type: none;

    &:after {
      clear: both;
      display: block;
      content: "";
    }

    span {
      display: inline-block;
      width: 2rem;
      margin-right: 2rem;
      text-align: center;
      float: left;

      i {
        font-size: 2.4rem;
        color: $color-orange-color;
        line-height: 2.7rem;
      }
    }

    p {
      display: inline-block;
      margin: 0;
      float: left;
      line-height: 2.7rem;
    }
  }
}

.project {
  background-size: cover;
  background: no-repeat center center;
  color: #fff;
  margin-bottom: 2rem;

  .project-title {
    font-size: 2.8rem;
    position: relative;
    margin-top: 3rem;
    &:after{
      background: #F04800;
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left:0;
      width: 4rem;
      height: 2px;
    }
  }

  .project-perex {
    margin-bottom: 3rem;
    margin-right: 3rem;
  }

  .project-arrow {
    position: absolute;
    right: 1.5rem;
    width: 1rem;
    height: 5rem;
    top: 50%;
    margin-top: -2.5rem;

    i {
      line-height: 5rem;
      font-size: 4.8rem;
      color: $color-orange-color;
    }
  }

  .project-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.footer {
  text-align: center;

  .social {
    display: inline-block;
    margin: 0 0 2.5rem;
    padding: 0;

    li {
      list-style-type: none;
      margin: 0;
      display: inline-block;
      padding: 0 1.8rem;

      i {
        font-size: 1.7rem;
      }
    }
  }

  p {
    font-size: 1.4rem;
  }

  .wz-head {
    margin: 2rem 0;
    width: 4rem;
  }
}

.projects {
  margin-bottom: 6rem;

  .project {
    margin-bottom: 0;
  }
}

.about {
  .about-text {

  }

  .about-head {
    width: 7%;
    @media screen and (max-width: 479px) {
      width: 25%;
    }
    max-width: 8rem;
    margin: 7rem auto 0;
    display: block;
  }

  .skills {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      text-align: center;
      margin-top: 4rem;

      span {

        i {
          color: $color-orange-color;
          font-size: 3.2rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.contact-info {
  text-align: center;
  margin-top: 2rem;

  div {
    font-size: 2.4rem;

    span {
      i {
        font-size: 4rem;
        color: $color-orange-color;
        margin: 4rem 0 2rem;
        display: block;

      }
    }
  }

  .button {
    margin-top: 4rem;
  }
}

.goal {
  margin: 4rem -1.5rem 0;
  padding: 3rem 0;
  background-size: cover;

  .section-title {
    margin-top: 0;
    color: #fff;
    margin-bottom: 2rem;
  }

  p {
    color: #fff;
  }
}

.process {

  p {
    margin: 2rem 0;
  }
}

.quote {
  background-size: cover;
  padding: 2rem 0;
  margin-top: 4rem;

  p {
    font-size: 2rem;
    color: #fff;

    span {
      display: block;
      font-size: 1.2rem;
      font-style: italic;
    }
  }
}

#menu,
#menu-responsive {
  display: none;
}

@media only screen and (max-width: 767px) {
  #menu-responsive {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: block;

      li {
        display: flex;
        height: 4rem;
        background-color: white;
        vertical-align: middle;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 600;
        left: 9.5rem;
        position: relative;
        box-shadow: 0 1px 1px 0 black;

        border-left: 3px solid white;
        &.active {
          border-left: 3px solid $color-orange-color;
        }

        transition: left 0.175s ease;
        &.open {
          left: 0;
        }

        .icon {
          display: flex;
          position: absolute;
          padding: 0 1rem;
          color: $color-orange-color;
        }

        a {
          color: $color-dark-color;
          margin-left: 4.4rem;
          margin-right: 1rem;
          cursor: pointer;
          &.active {
            color: $color-orange-color;
          }
        }
      }
    }
  }
}

@media only screen and (min-width:768px) {

  body {
    padding-left: 20rem;
    padding-bottom: 0;
  }

  #header {
    height: 100vh;
    &.half-height {
      height: 50vh;
    }
  }

  h2 {
    &.section-title {
      font-size: 4.8rem;
      padding-bottom: 0.5rem;
      margin: 8rem 0 3rem;

      &:after{
        width: 6rem;
        height: 0.4rem;
      }
    }
  }

  #menu {
    display: block;
    position: fixed;
    width: 20rem;
    height: 100vh;
    top: 0;
    left: 0;
    background: #ececec;
    z-index: 1000;

    .logo-menu {
      margin: 5rem 0 2.5rem;
      text-align: left;
      padding: 0 3rem;
      display: block;

      img {
        width: 100%;
        max-width: 22rem;
        filter: brightness(0.25);
      }
    }

    ul.social {
      font-size: 1.7rem;
      display: block;
      position: absolute;
      bottom: 1rem;
      left: 0;
      right: 0;
      padding: 0;
      text-align: center;

      li {
        list-style-type: none;
        margin: 0;
        display: inline-block;
        padding: 0 1rem;
      }
    }

    ul#nav {
      margin: 0;
      padding: 0;
      width: 100%;
      font-size: 1.6rem;

      li {
        display: block;
        list-style-type: none;
        float: left;
        width: 100%;
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 0;

        &:last-child {
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }

        a {
          height: 6.5rem;
          display: block;
          text-align: left;
          color: rgba(102,102,102,0.7);
          font-size: 1.5rem;
          border-top: 0;
          padding: 0 0.8rem 0 0;
          border-right: 5px solid rgba(102,102,102,0.07);
          line-height: 6.5rem;
          transition: all 0.5s ease;

          span {
            display: block;
            float: left;
            padding-left: 3rem;
            text-align: center;
            width: 7rem;
            @media screen and(max-width: 767px) {
              width: auto;
            }
            i {
              vertical-align: middle;
              font-size: 2rem;
            }
          }

          &:hover {
            color: $color-hover-color;
            border-right-color: $color-hover-color;
          }

          &.active {
            color: $color-orange-color;
            border-right-color: $color-orange-color;
          }
        }

      }
    }
  }

  #header {

    .logo {
      display: none;
      &.logo-home {
        display: block;
      }
    }

    .motto {
      position: absolute;
      bottom: 11rem;
      text-align: center;
      left: 0;
      right: 0;

      h1 {
        margin: 0;
        font-size: 6.4rem;
        color: #fff;
      }

      p {
        color: #fff;
        font-size: 2.6rem;
        margin-top: 0;
      }
    }

    .arrow {
      bottom: 5rem;
    }

  }

  #benefits {

    li {
      width:50%;
      float: left;

      span {
        font-size: 2.6rem;
        line-height: 3.2rem;
      }

      p {
        font-size: 2rem;
        line-height: 3.2rem;
      }
    }
  }

  .project {
    overflow: hidden;

    .project-title {
      font-size: 3.6rem;
      padding-bottom: 0.5rem;
      margin-top: 6rem;

      &:after {
        width: 6rem;
        height: 0.4rem;
      }
    }

    .project-perex {
      font-size: 1.8rem;
      margin-bottom: 12rem;
    }

    .project-arrow {
      right: -3rem;
      width: 2rem;
      height: 7rem;
      margin-top: -3.5rem;
      transition: all 0.3s ease;

      i {
        line-height: 7rem;
        font-size: 5.8rem;
      }
    }

    &:hover {

      .project-arrow {
        right: 3rem;
      }
    }

  }

  .button {
    width: 358px;
  }

  .about {

    .skills {

      li {
        width: 50%;
        float: left;
        padding: 0 3.5rem;

        p {
          font-size: 2rem;
          line-height: 3.2rem;
        }
      }
    }
  }
}

@media only screen and (min-width:1300px) {
  body {
    padding-left: 300px;
  }

  #menu {
    width: 300px;

    .logo-menu {
      margin: 8rem 0 4rem;
    }
  }

  .project {
    padding: 2.5rem 1.5rem;
  }

  #call {
    padding: 4rem 0;
  }
}

@media only screen and (min-width:1900px) {
  body {
    padding-left: 360px;
  }

  #menu {
    width: 360px;

    .logo-menu {
      margin: 11rem 0 5.5rem;
    }

    ul.social {
      font-size: 2.4rem;

      li {
        padding: 0 1.8rem;
      }
    }
  }

  #header {
    .motto {
      bottom: 200px;

      h1 {
        font-size: 8.4rem;
      }

      .arrow {
        bottom: 8rem;
      }
    }
  }

  .project {
    padding: 5rem 1.5rem;
  }

  #call {
    padding: 6rem 0;
  }

  #benefits {
    li {
      width:25%;
      float: left;
      margin: 2rem 0;

      span {
        font-size: 2.6rem;
        line-height: 3.2rem;
      }

      p {
        font-size: 2rem;
        line-height: 3.2rem;
      }
    }
  }
}
