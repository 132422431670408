.till-now {

  h4 {
    margin-top: 30px;
    font-size: 22px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 32px;
    margin-top: 10px;
    color: $color-orange-color;
  }
}

a.muted-link {
  color: $color-dark-color;
  &:hover {
    color: $color-orange-color
  }
}

.width-full {
  width: 100%;
}

.button-inverted-hover {
  &:hover {
    background-color: $color-orange-color !important;
    border-color: $color-orange-color !important;
    color: white !important;
  }
}

.height-min-200 {
  min-height: 200px !important;
  @media screen and (max-width: 992px) {
    min-height: 1px !important;
  }
}