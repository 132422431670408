/*
  Bebas Neue
*/
@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/bebas/bebasneue_bold.eot');
  src: url('../fonts/bebas/bebasneue_bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/bebas/bebasneue_bold.woff2') format('woff2'),
  url('../fonts/bebas/bebasneue_bold.woff') format('woff'),
  url('../fonts/bebas/bebasneue_bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/*
  Source sans Pro
*/
/* source-sans-pro-200 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-200italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-200italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-300 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-300italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-300italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-700italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'),
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro/source-sans-pro-v11-latin_latin-ext-900italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/*
  Font awesome
*/

$fa-font-path: "../fonts/fontawesome/webfonts";
@import "../fonts/fontawesome/scss/fontawesome";
@import "../fonts/fontawesome/scss/fa-solid";
@import "../fonts/fontawesome/scss/fa-regular";
@import "../fonts/fontawesome/scss/fa-brands";
