.two-columns {
  column-count: 2;
  column-gap: 3rem;
}

@media (max-width: 767px) {
  .two-columns {
    column-count: 1;
    column-gap: 0;
  }
}

a.oc-link-strong {
  font-weight: 600;
}

a.oc-link-green {
  color: green;
}

.margin-sides-60px {
  margin-left: 60px;
  margin-right: 60px;
}

.margin-sides-55px {
  margin-left: 55px;
  margin-right: 55px;
}

.margin-sides-45px {
  margin-left: 45px;
  margin-right: 45px;
}

.margin-sides-15px-max-sm {
  @media screen and (max-width: 767px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.margin-bottom-50px {
  margin-bottom: 50px;
}

.wz-orange {
  color: $color-orange-color;
}

.spacer-horizontal {
  margin-bottom: 6rem;
}