@import "mixins";
@import "variables";
@import "fonts";

$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';

$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';

$image-path: '~jquery-fancybox/source/img/';
@import '~jquery-fancybox/source/scss/jquery.fancybox.scss';

@import "atomic";
@import "layout";
@import "theme";
@import "features/shared";
@import "features/about";
@import "features/contact";
@import "features/development";
@import "features/home";
@import "features/marketing";
@import "features/webdesign";

@import "features/lanove-centrum";
@import "features/centrum-olympia";


// fixes safari bootstrap clearfix issue:

.row:before, .row:after {
  content: "";
  display: block;
  visibility: hidden;
}