//@import "../variables";

.row.icons {
  font-size: 32px;
  color: $color-orange-color;

  h4 {
    font-size: 22px;
    color: #333;
  }
}