@mixin size($value) {
  width: $value;
  height: $value;
}

@mixin pad-top-x {
  @for $i from 1 to 8 {
    .pad--top-#{$i * 10} {
      padding-top: #{$i*10}px;
    }
  }
}

@include pad-top-x();

@mixin margin-top-x {
  @for $i from 1 to 8 {
    .margin--top-#{$i * 10} {
      margin-top: #{$i*10}px;
    }
  }
}

@include margin-top-x();


.text--justify {
  text-align: justify;
}

.customer-quote {
  font-size: 1.6rem;
  line-height: 2rem;
  position: relative;
  padding: 15px 15px;

  .quote-icon {
    position: absolute;
    color: $color-orange-color;
    z-index: -1;
    opacity: 0.1;
  }

  .quote-icon-start {
    left: -10px;
    top: -10px;
  }

  .quote-icon-end {
    right: -10px;
    bottom: -10px;
  }

  p:last-of-type {
    text-align: right;
    font-style: italic;
    margin-top: 2em;
  }
}
