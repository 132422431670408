@import "../variables";

.grecaptcha-badge {
  display: none;
}

.contact-form {
  .message {
    margin: 0 0 60px;
  }

  .contact-info {
    margin-bottom: 30px;
  }
}

.contact-form {
  margin-bottom: 3rem;

  .inputs {
    text-align: center;

    input,
    textarea {
      width: 100%;
      margin: 10px 0;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 2px;
      padding: 8px;
      outline: 0;
      color: #777;
      transition: all 0.3s ease;

      &:focus {
        border-color: $color-dark-color;
        color: $color-dark-color;
      }

      &.has-error {
        border-bottom: 1px solid #a94442;
      }

      ::placeholder {
        color: #b7b7b7;
        font-size: 14px;
        font-family: 'Source Sans Pro', sans-serif;
      }
    }

    textarea {
      height: 218px;
    }
  }

  p {
    margin-top: 30px;

    &.error-box {
      margin-top: 0;
      margin-bottom: 20px;
      text-align: left;
      font-size: 13px;
    }
  }
}
